import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import settingsParams from '../../components/BookOnline/settingsParams';
import {
  FilterOption,
  FilterServicesByOptions,
  EnrichedService,
} from '../../types/types';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import {
  getUrlQueryParamValue,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  Category,
  Location,
  LocationType,
} from '@wix/ambassador-bookings-services-v2-service/types';

export const createFilterOptions = ({
  services,
  categories,
  businessLocations,
  flowAPI,
}: {
  services: EnrichedService[];
  categories: Category[];
  businessLocations: Location[];
  flowAPI: ControllerFlowAPI;
}): FilterOption[] => {
  const {
    settings,
    translations: { t },
  } = flowAPI;

  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  let filterOptions: FilterOption[];

  if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
    filterOptions = categories.map((category: any) => {
      return {
        id: category.id,
        title: category.name,
        isSelected: false,
      };
    });
  } else {
    const sortLocationsAlphabetically = (location1: any, location2: any) =>
      location1.title > location2.title ? 1 : -1;
    const sortedFilterLocations = businessLocations
      .map((location) => {
        return {
          id: location.business?.id || '',
          title: location.business?.name || '',
          isSelected: false,
        };
      })
      .sort(sortLocationsAlphabetically);
    const shouldShowOtherLocations = services.some((service) =>
      service.locations?.find(
        (location) => location?.type !== LocationType.BUSINESS,
      ),
    );
    filterOptions = [
      ...sortedFilterLocations,
      ...(shouldShowOtherLocations
        ? [
            {
              id: ReservedLocationIds.OTHER_LOCATIONS,
              title: t(
                'service-list.categories.location-categories.other-locations',
              ),
              isSelected: false,
            },
          ]
        : []),
    ];
  }
  const showAllServicesFilterOption =
    settings.get(settingsParams.showAllServicesFilterOption) &&
    filterOptions.length > 1;

  const preSelectedCategory = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    REQUESTED_CATEGORIES_URL_PARAM_NAME as BookingsQueryParams,
  );

  const hasCategoryDeepLink = !!preSelectedCategory;
  if (showAllServicesFilterOption) {
    filterOptions.unshift({
      id: ALL_SERVICES,
      title:
        settings.get(settingsParams.allServices) ||
        t('category-name.all-services'),
      isSelected: true,
    });
  } else if (filterOptions.length > 0) {
    filterOptions[0].isSelected = true;
  }

  if (
    hasCategoryDeepLink &&
    filterServicesBy === FilterServicesByOptions.CATEGORIES
  ) {
    filterOptions.forEach((filterOption) => {
      filterOption.isSelected = filterOption.id === preSelectedCategory;
    });
    if (
      filterOptions.every((filterOption) => !filterOption.isSelected) &&
      filterOptions.length > 0
    ) {
      filterOptions[0].isSelected = true;
    }
  }

  return filterOptions;
};
