import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BookingsAPI, ServiceAvailabilityMap } from '../../api/BookingsApi';
import { enrichServices } from '../services/services';
import { getAdditionalServicesData } from '../../actions/getAdditionalServicesData/getAdditionalServicesData';
import { EnrichedService, ServicesPagingMetadata } from '../../types/types';
import { BookingsQueryParams } from '@wix/bookings-catalog-calendar-viewer-utils';
import { calculateNumberOfPages } from '../pagination/pagination';
import settingsParams from '../../components/BookOnline/settingsParams';

export const getEnrichedServicesAndAvailability = async ({
  bookingsApi,
  flowAPI,
  activeFeatures,
  isPricingPlanInstalled,
  isAnywhereFlow,
  requestedPage,
  selectedFilterOptionId,
}: {
  flowAPI: ControllerFlowAPI;
  activeFeatures: any;
  isPricingPlanInstalled: boolean;
  isAnywhereFlow: boolean;
  bookingsApi: BookingsAPI;
  requestedPage?: number;
  selectedFilterOptionId?: string;
}): Promise<{
  pagingMetadata: ServicesPagingMetadata;
  services: EnrichedService[];
  coursesAvailability: ServiceAvailabilityMap | undefined;
}> => {
  if (requestedPage && requestedPage !== 1) {
    flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
      [BookingsQueryParams.PAGE]: requestedPage.toString(),
    });
  } else {
    flowAPI.controllerConfig.wixCodeApi.location.queryParams.remove([
      BookingsQueryParams.PAGE,
    ]);
  }

  const queryServicesResponse = await bookingsApi.queryServices({
    selectedFilterOptionId,
  });

  const enrichedServices = await enrichServices({
    flowAPI,
    activeFeatures,
    isPricingPlanInstalled,
    services: queryServicesResponse.services || [],
    isAnywhereFlow,
  });

  const { services, coursesAvailability } = await getAdditionalServicesData({
    bookingsApi,
    services: enrichedServices,
  });
  return {
    services,
    coursesAvailability,
    pagingMetadata: {
      totalPages: calculateNumberOfPages(
        flowAPI.settings.get(settingsParams.servicesPerPage),
        queryServicesResponse.pagingMetadata?.total,
      ),
      maxPageLoaded: requestedPage || 1,
      minPageLoaded: requestedPage || 1,
    },
  };
};
