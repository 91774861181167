import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import {
  CatalogServiceDto,
  ServiceLocation,
  ServiceLocationType,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DisplayServicesByOptions, Resources } from '../../types/types';
import { WidgetData } from '../../../legacy/types';
import {
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../../../legacy/appKeys/DefaultSettings';
import settingsParams from '../../components/BookOnline/settingsParams';

const getCategoriesForSelectedServices = (
  categories: OfferingCategoryDto[],
  selectedServices: CatalogServiceDto[],
): OfferingCategoryDto[] => {
  const selectedCategoriesIds: Set<string> = new Set(
    selectedServices.map((service) => service.categoryId),
  );

  return categories.filter((category) =>
    selectedCategoriesIds.has(category.id),
  );
};

const getBusinessLocationsForSelectedServices = (
  selectedServices: CatalogServiceDto[],
): ServiceLocation[] => {
  const uniqueBusinessIds: Set<string> = new Set<string>();
  const uniqueBusinessLocations: ServiceLocation[] = [];
  selectedServices.forEach((service) => {
    service.info.locations?.forEach((location) => {
      if (
        location?.type === ServiceLocationType.OWNER_BUSINESS &&
        location?.businessLocation &&
        !uniqueBusinessIds.has(location.businessLocation.id!)
      ) {
        uniqueBusinessIds.add(location.businessLocation.id!);
        uniqueBusinessLocations.push(location);
      }
    });
  });
  return uniqueBusinessLocations;
};

const getBusinessLocationsForSelectedServicesAndLocations = (
  selectedServices: CatalogServiceDto[],
  selectedLocationsIds: string[],
): ServiceLocation[] => {
  const businessLocations =
    getBusinessLocationsForSelectedServices(selectedServices);
  return businessLocations.filter((location) =>
    selectedLocationsIds.includes(location?.businessLocation?.id!),
  );
};

export const filterWidgetData = (
  userData: WidgetData,
  flowAPI: ControllerFlowAPI,
  presetId: string,
  isEditor: boolean,
): Resources => {
  if (!isEditor) {
    return {
      offerings: userData.offerings,
      categories: userData.categories,
      locations: userData.locations,
    };
  }

  const { settings, experiments } = flowAPI;

  if (userData.offerings?.length) {
    if (
      presetId === SINGLE_SERVICE_PRESET_ID ||
      presetId === SINGLE_SERVICE_EDITOR_X_PRESET_ID
    ) {
      return filterDataBySelectedService(
        settings.get(settingsParams.selectedService),
        userData,
      );
    } else {
      const displayServicesBy = settings.get(settingsParams.displayServicesBy);
      const selectedCategories = settings.get(
        settingsParams.selectedCategories,
      );
      const selectedLocations = settings.get(settingsParams.selectedLocations);
      const selectedServices = settings.get(settingsParams.selectedServices);
      if (
        displayServicesBy === DisplayServicesByOptions.BY_SERVICES &&
        selectedCategories?.length
      ) {
        return filterDataBySelectedCategories(selectedCategories, userData);
      } else if (
        displayServicesBy === DisplayServicesByOptions.BY_SPECIFIC_SERVICES &&
        selectedServices?.length
      ) {
        return filterDataBySelectedServices(selectedServices, userData);
      } else if (
        displayServicesBy === DisplayServicesByOptions.BY_LOCATIONS &&
        selectedLocations?.length
      ) {
        return filterDataBySelectedLocations(selectedLocations, userData);
      } else {
        return {
          offerings: userData.offerings,
          categories: userData.categories,
          locations: userData.locations,
        };
      }
    }
  } else {
    return {
      offerings: [],
      categories: [],
      locations: [],
    };
  }
};

const filterDataBySelectedService = (
  selectedServiceId: string,
  userData: WidgetData,
): Resources => {
  const selectedService = selectedServiceId
    ? userData.offerings.find((service) => service.id === selectedServiceId)
    : userData.offerings[0];
  const selectedServices: CatalogServiceDto[] = selectedService
    ? [selectedService]
    : [];
  const categories: OfferingCategoryDto[] = getCategoriesForSelectedServices(
    userData.categories,
    selectedServices,
  );

  return {
    offerings: selectedServices,
    categories,
    locations: [],
  };
};

const filterDataBySelectedCategories = (
  selectedCategories: string[],
  userData: WidgetData,
): Resources => {
  const selectedServices = userData.offerings.filter((service) =>
    selectedCategories.includes(service.categoryId),
  );
  const categories: OfferingCategoryDto[] = getCategoriesForSelectedServices(
    userData.categories,
    selectedServices,
  );
  const businessLocations: ServiceLocation[] =
    getBusinessLocationsForSelectedServices(selectedServices);
  return {
    offerings: selectedServices,
    categories,
    locations: businessLocations,
  };
};

const filterDataBySelectedServices = (
  selectedServices: string[],
  userData: WidgetData,
): Resources => {
  const services = userData.offerings.filter((service) =>
    selectedServices.includes(service.id),
  );
  const categories: OfferingCategoryDto[] = getCategoriesForSelectedServices(
    userData.categories,
    services,
  );
  const businessLocations: ServiceLocation[] =
    getBusinessLocationsForSelectedServices(services);
  return {
    offerings: services,
    categories,
    locations: businessLocations,
  };
};

const filterDataBySelectedLocations = (
  selectedLocations: string[],
  userData: WidgetData,
): Resources => {
  const selectedServices = userData.offerings.filter((service) =>
    service.info.locations!.some((location) => {
      if (location.type === ServiceLocationType.OWNER_BUSINESS) {
        return selectedLocations.includes(location.businessLocation?.id!);
      }
      return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
    }),
  );
  const categories: OfferingCategoryDto[] = getCategoriesForSelectedServices(
    userData.categories,
    selectedServices,
  );

  const businessLocations: ServiceLocation[] =
    getBusinessLocationsForSelectedServicesAndLocations(
      selectedServices,
      selectedLocations,
    );

  return {
    offerings: selectedServices,
    categories,
    locations: businessLocations,
  };
};
